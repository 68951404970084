@import './variables.scss';

.postings-index {
  height: 100%;
  overflow: hidden;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.postings-pagination {
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
}

.sort-dropdown {
  position: relative;
}

button {
  border: 0;
  background: inherit;
  cursor: pointer;
  font-size: inherit;
}

.sort-dropdown-button, .sort-dropdown-option {
  cursor: pointer;
}

.sort-dropdown-button:hover {
  text-decoration: underline $mediumgray;
}

.sort-dropdown-option:hover {
  background-color: $lightgray;
}

.sort-dropdown-option {
  display: flex;
  align-items: center;
  padding: 8px 12px;

  span {
    text-wrap: nowrap;
  }
}
.filter-container-employment-type {
  text-wrap: nowrap;
}

.sort-dropdown-options-container {
  position: absolute;
  right: auto;
  z-index: 2;
  margin-top: 2px;
  background-color: white;
  border: 1px solid $lightgray;
  box-shadow: 0 5px 5px rgba(#000, 0.3);

  .sort-dropdown-option {
    margin: 0;
  }
}

.fa-caret {
  color: $mediumgray;
  margin-left: 6px;
}

.fa-check {
  margin-right: 6px;
}

.fa-check.hidden {
  color: rgba(0,0,0,0);
}

.fa-check.present {
  color: $lightblue;
}

.postings-index-list {
  @include scrollbars;
  height: 100%;
  overflow-y: scroll;
  padding-right: 5px;
  margin: 5px 0;
}
