// Theme Colors
$darkblue: #003e5d;
$lightblue: #0176b2;
$mediumgray: #515151;
$lightgray: #e3e3e3;

// Scrollbar mixin
@mixin scrollbars() {
  $size: 8px;
  $radius: 4px;
  $foreground-color: $lightblue;
  $background-color: white;

  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $foreground-color;
    border-radius: $radius;
  }

  & {
    scrollbar-width: thin;
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }

  scrollbar-color: $foreground-color $background-color;
}
