@import './variables.scss';

.filter-container {
  display: flex;
  margin: 0 15px;

  div {
    margin-right: 10px;
  }
  .filter-group {
    display: flex;
    margin: 2px 0;
  }
}

.filter-container-title {
  display: flex;
  flex-direction: column;
}

.filter-container-button {
  text-wrap: nowrap;
  margin-left: auto;
  svg, .button-text {
    color: $darkblue;
  }
  &:hover{
    svg, .button-text{
      color: $lightblue;
    }
  }

  .button-text{
    margin-left: 5px;
  }
}

@media (max-width: 1160px) {
  .filter-container {
    flex-direction: column;
  }
  .filter-container-button {
    margin: 0px;
  }
}

@media (max-width: 820px) {
  .filter-group {
    flex-direction: column;
    div, button {
      margin: 5px 0;
    }
  }
  .filter-container-button {
    width: max-content;
  }
}

@media (max-height: 600px), (max-width: 700px) {
  .filter-container-button.toggle-map {
    display: none;
  }
}
