@import './variables.scss';

[class*="-filter-dropdown"] {
  position: relative;
  display: flex;
  flex-direction: column;
}

[class*="-filter-dropdown-options-container"] {
  @include scrollbars;
  padding: 0 5px;
  position: absolute;
  top: 100%;
  z-index: 2;
  margin-top: 2px;
  background-color: white;
  border: 1px solid $lightgray;
  box-shadow: 0 5px 5px rgba(#000, 0.3);
  max-height: 10em;
  cursor: pointer;
  overflow-y: auto;
  overflow-x: hidden;
}
