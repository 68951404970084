@import './variables.scss';

body {
  margin: 0;
  font-family: Noto Sans,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, span {
  color: $mediumgray;
}

ul {
  list-style: none;
  padding: 0;
}

body, html, #root, .app {
  height: 100%;
}
