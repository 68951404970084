@import './variables.scss';

.postings-index-item {
  padding: 15px 0;
  border-top: 2px solid $lightgray;
}

a {
  font-size: 26px;
  cursor: pointer;
  text-decoration: none;
}

a:visited {
  color: $darkblue;
}

a:link, a:hover{
  color: $lightblue;
}

p {
  margin: 0;
}

.postings-index-item-link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.postings-index-item-posting-date {
  text-align: end;
}

.postings-index-item-address-container {
  margin-top: 10px;
}
