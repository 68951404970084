[class*='map-container'] {
  margin: 15px 0;
  height: 300px;
  width: 80%;
  align-self: center;
  flex-shrink: 0;
}

.map-container-loading {
  background-color: lightblue;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-height: 600px), (max-width: 700px) {
  [class*='map-container'] {
    display: none;
  }
}
