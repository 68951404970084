@import './variables.scss';

.App {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
}

.careers-header {
  height: min-content;

  h1{
    font-size: 36px;
    font-weight: 400;
    color: $mediumgray;
  }
}

.logo-container {
  margin-bottom: 10px;
  padding-left: 5px;
  background-color: rgb(57,57,57);
  border-bottom: 4px solid rgb(68,176,228);
}

.header-logo {
  max-width: 770px;
  width: 50%;
}

.content-container {
  overflow: hidden;
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
